<template lang="pug">
  v-container#inv(fluid='' tag='section' style="padding: 0px;")
    v-dialog(v-if="dialogImport" v-model='dialogImport' max-width="600px")
      v-card
        v-toolbar(text='' color='primary' dark='')
          v-toolbar-title
            h4 Importar Proveedores
            v-btn(icon="" dark="" @click="dialogImport = false" style="position: absolute; top: 0px; right: 10px;")
              v-icon.primary.rounded mdi-close
        v-card-text(style='padding-bottom: 0;')
          div(style="widht: 100% text-align: center;")
            a(class="download-excel" href="https://firebasestorage.googleapis.com/v0/b/j2rinventor.appspot.com/o/formatos%2Fformato-importar-proveedores.xlsx?alt=media&token=b1349dea-998e-428f-a3ef-84355b10f71b") Descargar Plantilla para Carga de Proveedores
            p
              strong(style="font-weight: bold;") CÓDIGOS DE IMPLEMENTACIÓN:
            v-row
              v-col(cols="12")
                p
                  strong(style="font-weight: bold;") Tipo de Persona
                p(style="margin-bottom: 0px;") Natural
                p(style="margin-bottom: 0px;") Jurídica
            input#inputExcel(type='file' style="padding-top: 10px; font-family: 'ceraProMedium', sans-serif !important;")
          v-row(style='align-items: center;')
            v-col(cols='12')
              p(style="margin-bottom: 0px;") Para asistencia contáctanos:
                v-btn.ml-2(min-width='0' text='' style="" @click="openLink('https://wa.me/+593984530998')")
                  v-icon(color="black") mdi-whatsapp
            //v-col(cols='6' style='text-align:end;')
              v-btn.ml-2(min-width='0' text='' style='' @click="openLink('https://www.facebook.com/dental.prime.391/')")
                v-icon(color="black") mdi-facebook
              v-btn.ml-2(min-width='0' text=''  style="" @click="openLink('https://www.instagram.com/dentalprimeapp/')")
                v-icon(color="black") mdi-instagram
        v-card-actions
          v-spacer
          v-btn.success-btn(text='' @click='dialogImport = false' :loading='loadingSaveProduct')
            | Finalizar
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-dialog(fullscreen="" v-model='dialodProveedor')
      v-card
        registro-proveedor-modal(@closeModal="closeModal" :providerToEdit='providerToEdit')
    v-dialog(fullscreen="" v-model='dialodProveedorExterno')
      v-card
        registro-proveedor-externo-modal(@closeModal="closeModalExterno" :providerToEdit='providerToEditExterno')
    v-dialog(fullscreen="" v-model='dialodAdvanceViewModal')
      v-card
        advance-data-view-modal(@closeModalAdvance="closeModalAdvance" :providerData='providerData')
    base-material-card.px-5.py-3(icon='mdi-account-multiple-plus' title='Lista de Proveedores' style='min-height: 100vh;')
      v-container#proveedor-table(fluid='' tag='section')
        v-tabs(v-model="tabsProvider" grow="" icons-and-text="" height="35px")
          v-tab(ripple href='#tab-1')   Internos
          v-tab(ripple href='#tab-2')   Externos
          v-tab-item(value='tab-1')
            v-row(style="margin-top: 20px;")
              v-col(cols="12" md="6" style="margin-bottom: 10px;")
                v-text-field(v-model="search" append-icon="mdi-search" label= "Buscar" single-line="" hide-details="" clearable)
              v-col(cols="12" md="2")
                v-btn.mr-0(color='primary' rounded='' @click='addNewProvider' style="width: 100%;")
                  strong
                    | NUEVO
                    br
                    | PROVEEDOR
              v-col(cols="12" md="2")
                v-btn.mr-0(color='green' rounded='' @click='importProviders' style="width: 100%;")
                  strong
                    | IMPORTAR
                    br
                    | PROVEEDORES
              v-col(cols="12" md="2")
                v-btn.mr-0(color='secondary' rounded='' @click='downLoadExcelFile' style="width: 100%;")
                  strong
                    | DESCARGAR
                    br
                    | LISTA
            v-skeleton-loader(:loading="cargando" type="table" v-if="firstLoad")
            v-data-table.mx-2#tablaDatos(:headers="headers" v-show="!firstLoad" :items="providersLst" item-key="uid" :search="search" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Proveedores por página'\}")
              template(v-slot:item.edit="{ item }")
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(text="" icon="" color='green' v-on="on" @click="openAdvanceView(item)")
                      v-icon(small) mdi-cash
                  span.tooltips Visualizar Anticipos
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(text="" icon="" color='blue' v-on="on" @click="editProvider(item)")
                      v-icon(small) mdi-lead-pencil
                  span.tooltips Editar Proveedor
              v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                | Sin resultados
              v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                | Sin datos
              v-flex(xs12='', sm10='', offset-sm1='')
          v-tab-item(value='tab-2')
            v-row(style="margin-top: 20px;")
              v-col(cols="12" md="6" style="margin-bottom: 10px;")
                v-text-field(v-model="search" append-icon="mdi-search" label= "Buscar" single-line="" hide-details="" clearable)
              v-col(cols="12" md="2")
                v-btn.mr-0(color='primary' rounded='' @click='addNewProviderExterno' style="width: 100%;")
                  strong
                    | NUEVO
                    br
                    | PROVEEDOR
              v-col(cols="12" md="2")
                v-btn.mr-0(color='green' rounded='' @click='importProvidersExterno' style="width: 100%;")
                  strong
                    | IMPORTAR
                    br
                    | EXTERNOS
              v-col(cols="12" md="2")
                v-btn.mr-0(color='secondary' rounded='' @click='downLoadExcelFileExterno' style="width: 100%;")
                  strong
                    | DESCARGAR
                    br
                    | LISTA
            v-skeleton-loader(:loading="cargando" type="table" v-if="firstLoad")
            v-data-table.mx-2#tablaDatos(:headers="headers" v-show="!firstLoad" :items="providersLstExterno" item-key="uid" :search="search" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Proveedores por página'\}")
              template(v-slot:item.edit="{ item }")
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(text="" icon="" color='green' v-on="on" @click="openAdvanceView(item)")
                      v-icon(small) mdi-cash
                  span.tooltips Visualizar Anticipos
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(text="" icon="" color='blue' v-on="on" @click="editProviderExterno(item)")
                      v-icon(small) mdi-lead-pencil
                  span.tooltips Editar Proveedor
              v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                | Sin resultados
              v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                | Sin datos
              v-flex(xs12='', sm10='', offset-sm1='')
</template>

<script>
  import readXlsxFile from 'read-excel-file'
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/database'
  import * as functions from '../../../functions.js'
  export default {
    components: {
      RegistroProveedorModal: () => import('@/views/dashboard/component/application/RegistroProveedorModal'),
      RegistroProveedorExternoModal: () => import('@/views/dashboard/component/application/RegistroProveedorExternoModal'),
      AdvanceDataViewModal: () => import('@/views/dashboard/component/application/AdvanceDataViewModal'),
    },
    data: () => ({
      providerData: {
        providerKey: null,
        external: false,
      },
      tabsProvider: 'tab-1',
      loadingSaveProduct: false,
      dialogImport: false,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      providerToEdit: {
        name: '',
        mail: '',
        personType: ['Natural'],
        ruc: '',
        telephone: '',
        phone: '',
        description: '',
        providerKey: '',
        direction: '',
        guideFrom: '',
        guideUntil: '',
        external: false,
      },
      providerToEditExterno: {
        name: '',
        mail: '',
        personType: ['Natural'],
        ruc: '',
        telephone: '',
        phone: '',
        description: '',
        providerKey: '',
        direction: '',
        guideFrom: '',
        guideUntil: '',
        external: true,
      },
      headers: [
        { text: 'Nombre', value: 'name', align: 'left', filterable: true },
        { text: 'Correo', value: 'mail', align: 'left', filterable: true },
        { text: 'Telefono', value: 'telephone', align: 'left', filterable: true },
        { text: 'Celular', value: 'phone', align: 'left', filterable: true },
        { text: 'Tipo Personería', value: 'personType', align: 'left', filterable: true },
        { text: 'RUC', value: 'ruc', align: 'left', filterable: true },
        { text: 'Editar', align: 'center', value: 'edit', sortable: false }, // No se cambia
        // { text: 'Borrar', align: 'center', value: 'delete', sortable: false }, // No se cambia
      ],
      ordenarPor: 'name',
      search: '',
      arrayItems: [],
      cargando: false,
      firstLoad: false,
      dialodProveedor: false,
      dialodProveedorExterno: false,
      dialodAdvanceViewModal: false,
    }),
    computed: {
      providersLst () {
        return this.$store.state.provider.lstProviders
      },
      providersLstExterno () {
        return this.$store.state.provider.lstProvidersExternos
      },
    },
    methods: {
      openAdvanceView (item) {
        this.providerData.providerKey = item.providerKey
        let banExternal = false
        if (item.external) {
          banExternal = true
        } else {
          banExternal = false
        }
        this.providerData = {
          providerKey: item.providerKey,
          external: banExternal,
        }
        this.dialodAdvanceViewModal = true
      },
      importProviders () {
        this.dialogImport = true
        setTimeout(() => {
          const inputExcel = document.getElementById('inputExcel')
          inputExcel.addEventListener('change', () => {
            readXlsxFile(inputExcel.files[0]).then(async (rows) => {
              this.loadingSaveProduct = true
              const providersLst = []
              let globalBan = true
              for (let i = 1; i < rows.length; i++) {
                let ban = true
                let uidVal = ''
                let msg = 'El campo {CAMPO} de la fila {FILA} es incorrecto. Por favor ingrese el campo indicado para continuar.'
                if (!rows[i][0] || rows[i][0].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Tipo de Persona')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }
                if (!rows[i][1] || rows[i][1].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Identificación')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }

                if (!rows[i][2] || rows[i][2].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Nombre Completo')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }

                if (!rows[i][4] || rows[i][4].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Teléfono')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }

                const providerValid = this.providersLst.filter(
                  item => {
                    const ideProvider = rows[i][1] ? rows[i][1] : ''
                    return (item.ruc === ideProvider)
                  })
                if (providerValid.length > 0) {
                  uidVal = providerValid[0].id
                }

                if (ban) {
                  const providerSend = {
                    name: rows[i][2] ? rows[i][2] : '',
                    mail: rows[i][5] ? rows[i][5] : '',
                    personType: rows[i][0] ? rows[i][0] : '',
                    ruc: rows[i][1] ? rows[i][1] : '',
                    telephone: rows[i][4] ? rows[i][4] : '',
                    phone: rows[i][6] ? rows[i][6] : '',
                    description: rows[i][3] ? rows[i][3] : '',
                    providerKey: '',
                  }
                  if (uidVal !== '') {
                    providerSend.providerKey = uidVal
                    providersLst.push(providerSend)
                  } else {
                    providersLst.push(providerSend)
                  }
                } else {
                  globalBan = false
                  this.dialogImport = false
                  this.loadingSaveProduct = false
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: msg,
                  }
                  return
                }
              }
              if (globalBan) {
                let banEnd = true
                let correctRegister = 0
                let errorRegister = 0
                if (providersLst.length > 0) {
                  for (let i = 0; i < providersLst.length; i++) {
                    if (!providersLst[i].providerKey || providersLst[i].providerKey === '') {
                      providersLst[i].providerKey = firebase.database().ref(`clients/${this.currentUserId}`).push().getKey()
                    }
                    providersLst[i].completeData = providersLst[i].name + ' | ' + providersLst[i].ruc + ' | ' + providersLst[i].mail
                    await this.$store.dispatch('provider/setProvider', [providersLst[i].providerKey, providersLst[i]]).then(resolve => {
                      correctRegister++
                    }, reject => {
                      banEnd = false
                      errorRegister++
                    })
                  }
                  if (banEnd) {
                    this.dialogImport = false
                    this.loadingSaveProduct = false
                    this.snackbar = {
                      show: true,
                      color: 'green',
                      text: 'Proceso finalizado con: ' + correctRegister + ' registros correctos y ' + errorRegister + ' registros incorrectos.',
                    }
                  } else {
                    this.dialogImport = false
                    this.loadingSaveProduct = false
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: 'Proceso finalizado con: ' + correctRegister + ' registros correctos y ' + errorRegister + ' registros incorrectos.',
                    }
                  }
                } else {
                  this.dialogImport = false
                  this.loadingSaveProduct = false
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: 'No se tiene registros para agregar.',
                  }
                }
              }
            }).catch((e) => {
              this.dialogImport = false
              this.loadingSaveProduct = false
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al momento de cargar el archivo. Verifique el archivo.',
              }
            })
          })
        }, 500)
      },
      importProvidersExterno () {
        this.dialogImport = true
        setTimeout(() => {
          const inputExcel = document.getElementById('inputExcel')
          inputExcel.addEventListener('change', () => {
            readXlsxFile(inputExcel.files[0]).then(async (rows) => {
              this.loadingSaveProduct = true
              const providersLst = []
              let globalBan = true
              for (let i = 1; i < rows.length; i++) {
                let ban = true
                let uidVal = ''
                let msg = 'El campo {CAMPO} de la fila {FILA} es incorrecto. Por favor ingrese el campo indicado para continuar.'
                if (!rows[i][0] || rows[i][0].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Tipo de Persona')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }
                if (!rows[i][1] || rows[i][1].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Identificación')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }

                if (!rows[i][2] || rows[i][2].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Nombre Completo')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }

                if (!rows[i][4] || rows[i][4].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Teléfono')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }

                const providerValid = this.providersLst.filter(
                  item => {
                    const ideProvider = rows[i][1] ? rows[i][1] : ''
                    return (item.ruc === ideProvider)
                  })
                if (providerValid.length > 0) {
                  uidVal = providerValid[0].id
                }

                if (ban) {
                  const providerSend = {
                    name: rows[i][2] ? rows[i][2] : '',
                    mail: rows[i][5] ? rows[i][5] : '',
                    personType: rows[i][0] ? rows[i][0] : '',
                    ruc: rows[i][1] ? rows[i][1] : '',
                    telephone: rows[i][4] ? rows[i][4] : '',
                    phone: rows[i][6] ? rows[i][6] : '',
                    description: rows[i][3] ? rows[i][3] : '',
                    providerKey: '',
                  }
                  if (uidVal !== '') {
                    providerSend.providerKey = uidVal
                    providersLst.push(providerSend)
                  } else {
                    providersLst.push(providerSend)
                  }
                } else {
                  globalBan = false
                  this.dialogImport = false
                  this.loadingSaveProduct = false
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: msg,
                  }
                  return
                }
              }
              if (globalBan) {
                let banEnd = true
                let correctRegister = 0
                let errorRegister = 0
                if (providersLst.length > 0) {
                  for (let i = 0; i < providersLst.length; i++) {
                    if (!providersLst[i].providerKey || providersLst[i].providerKey === '') {
                      providersLst[i].providerKey = firebase.database().ref(`clients/${this.currentUserId}`).push().getKey()
                    }
                    providersLst[i].completeData = providersLst[i].name + ' | ' + providersLst[i].ruc + ' | ' + providersLst[i].mail
                    await this.$store.dispatch('provider/setProviderExterno', [providersLst[i].providerKey, providersLst[i]]).then(resolve => {
                      correctRegister++
                    }, reject => {
                      banEnd = false
                      errorRegister++
                    })
                  }
                  if (banEnd) {
                    this.dialogImport = false
                    this.loadingSaveProduct = false
                    this.snackbar = {
                      show: true,
                      color: 'green',
                      text: 'Proceso finalizado con: ' + correctRegister + ' registros correctos y ' + errorRegister + ' registros incorrectos.',
                    }
                  } else {
                    this.dialogImport = false
                    this.loadingSaveProduct = false
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: 'Proceso finalizado con: ' + correctRegister + ' registros correctos y ' + errorRegister + ' registros incorrectos.',
                    }
                  }
                } else {
                  this.dialogImport = false
                  this.loadingSaveProduct = false
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: 'No se tiene registros para agregar.',
                  }
                }
              }
            }).catch((e) => {
              this.dialogImport = false
              this.loadingSaveProduct = false
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al momento de cargar el archivo. Verifique el archivo.',
              }
            })
          })
        }, 500)
      },
      editProvider (itemToEdit) {
        this.dialodProveedor = true
        this.providerToEdit = itemToEdit
      },
      editProviderExterno (itemToEdit) {
        this.dialodProveedorExterno = true
        this.providerToEditExterno = itemToEdit
      },
      async deleteProvider (providerToDelete) {
        await this.$store.dispatch('provider/deleteProvider', providerToDelete.providerKey).then(() => {
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Proveedor eliminado correctamente',
          }
        }).catch(() => {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al eliminar el proveedor',
          }
        })
      },
      addNewProvider () {
        this.dialodProveedor = true
        this.providerToEdit = {
          name: '',
          mail: '',
          personType: ['Natural'],
          ruc: '',
          telephone: '',
          phone: '',
          description: '',
          providerKey: '',
          direction: '',
          guideFrom: '',
          guideUntil: '',
          external: false,
        }
      },
      addNewProviderExterno () {
        this.dialodProveedorExterno = true
        this.providerToEditExterno = {
          name: '',
          mail: '',
          personType: ['Natural'],
          ruc: '',
          telephone: '',
          phone: '',
          description: '',
          providerKey: '',
          direction: '',
          guideFrom: '',
          guideUntil: '',
          external: true,
        }
      },
      async closeModal () {
        this.dialodProveedor = false
      },
      async closeModalExterno () {
        this.dialodProveedorExterno = false
      },
      async closeModalAdvance () {
        this.dialodAdvanceViewModal = false
      },
      downLoadExcelFile () {
        const data = []
        if (this.providersLst) {
          for (let i = 0; i < this.providersLst.length; i++) {
            // se modifica la cedula para que sea legible en excel
            var providerId = this.providersLst[i].ruc
            var separator = '-'
            var position = this.providersLst[i].ruc.length - 1
            var provIdFormat = [providerId.slice(0, position), separator, providerId.slice(position)].join('')
            data.push({
              'Nombre de proveedor': this.providersLst[i].name,
              Correo: this.providersLst[i].mail,
              'Tipo de personeria': this.providersLst[i].personType,
              'RUC/Cedula': provIdFormat,
              Telefono: this.providersLst[i].telephone,
              Celular: this.providersLst[i].phone,
            })
          }
          functions.JSONToCSVConvertor(data, 'Proveedores', true)
        }
      },
      downLoadExcelFileExterno () {
        const data = []
        if (this.providersLstExterno()) {
          for (let i = 0; i < this.providersLstExterno().length; i++) {
            // se modifica la cedula para que sea legible en excel
            var providerId = this.providersLstExterno()[i].ruc
            var separator = '-'
            var position = this.providersLstExterno()[i].ruc.length - 1
            var provIdFormat = [providerId.slice(0, position), separator, providerId.slice(position)].join('')
            data.push({
              'Nombre de proveedor': this.providersLstExterno()[i].name,
              Correo: this.providersLstExterno()[i].mail,
              'Tipo de personeria': this.providersLstExterno()[i].personType,
              'RUC/Cedula': provIdFormat,
              Telefono: this.providersLstExterno()[i].telephone,
              Celular: this.providersLstExterno()[i].phone,
            })
          }
          functions.JSONToCSVConvertor(data, 'Proveedores-Externos', true)
        }
      },
    },
  }
</script>
